﻿'use strict';

import {
    $FILTER_AVAILABLE,
    $FILTER_OPTION_1,
    $FILTER_OPTION_2,
    $FILTER_OPTION_3,
    $FILTER_TAGS,
    $FILTER_MIN_PRICE,
    $FILTER_MAX_PRICE
} from './constants';

import {
    FilterCategory
} from './product-filter'



export function OptionObj(optName, optValue) {
    this.optionName = optName;
    this.optionValue = optValue;
}

export function getAllCheckedProductFilter() {
    var optionList1 = [];
    var optionList2 = [];
    var optionList3 = [];
    var tagList = [];
    var available = "";

    $FILTER_AVAILABLE.each(function () {

        let $CHECK_STATE = $(this).prop("checked");

        if ($CHECK_STATE == true) {
            available = $(this).val();
        }
    })

    $FILTER_OPTION_1.each(function () {

        let $OPTION1_NAME = "";
        let $OPTION1_VAL = "";
        let $CHECK_STATE = $(this).prop("checked");
        if ($CHECK_STATE == true) {
            $OPTION1_NAME = $(this).data("optionName");
            $OPTION1_VAL = $(this).val();
            let option1 = new OptionObj($OPTION1_NAME, $OPTION1_VAL);
            optionList1.push(option1);
        }
    });

    $FILTER_OPTION_2.each(function () {

        let $OPTION2_NAME = "";
        let $OPTION2_VAL = "";
        let $CHECK_STATE = $(this).prop("checked");
        if ($CHECK_STATE == true) {
            $OPTION2_NAME = $(this).data("optionName");
            $OPTION2_VAL = $(this).val();
            let option2 = new OptionObj($OPTION2_NAME, $OPTION2_VAL);
            optionList2.push(option2);
        }
    });

    $FILTER_OPTION_3.each(function () {

        let $OPTION3_NAME = "";
        let $OPTION3_VAL = "";
        let $CHECK_STATE = $(this).prop("checked");
        if ($CHECK_STATE == true) {
            $OPTION3_NAME = $(this).data("optionName");
            $OPTION3_VAL = $(this).val();
            let option3 = new OptionObj($OPTION3_NAME, $OPTION3_VAL);
            optionList3.push(option3);
        }
    });


    $FILTER_TAGS.each(function () {
        let $TAGS_$CHECK_STATE = $(this).prop("checked");
        let $TAG = "";

        if ($TAGS_$CHECK_STATE == true) {
            $TAG = $(this).val();
            tagList.push($TAG);
        }
    });

    let minPrice = $FILTER_MIN_PRICE.val();

    let maxPrice = $FILTER_MAX_PRICE.val();

    let toReturn = new FilterCategory(available, tagList, optionList1, optionList2, optionList3, minPrice, maxPrice, "", "");

    return toReturn;
}