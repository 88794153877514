﻿'use strict';

import {
    $SORT_BY_FILTER_SELECT
} from './constants';

import {
    createNewHrefProductFilter
} from './product-filter'

import {
    getAllCheckedProductFilter
} from './product-set-state'



if ($SORT_BY_FILTER_SELECT().length) {

    $SORT_BY_FILTER_SELECT().on('change', function () {

        let sortBy = $(this).val();

        let allChecked = getAllCheckedProductFilter();

        allChecked.sortBy = sortBy;

        let newHref = createNewHrefProductFilter(allChecked);

        window.location.replace(newHref);

    });

}
