﻿'use strict';

import {
    SEARCH_INPUT_ELEM
} from './constants';

if (SEARCH_INPUT_ELEM().length) {
    SEARCH_INPUT_ELEM().on('keydown', function (e) {

        if (e.which == 13) {

            let value = $(this).val();

            let isAjax = $(this).data('ajax') == 'true';

            if (isAjax) {

            }
            else {

                window.location.href = `/search?q=${value}`;

            }

        }

    });
}