﻿export const $PAGE_SIZE_FILTER_SELECT = () => $('select[data-action="page-size-filter"]');

export const $SORT_BY_FILTER_SELECT = () => $('select[data-action="sort-by-filter"]');

export const $FILTER_AVAILABLE = $('[data-identity="filter-available"]');

export const $FILTER_TAGS = $('[data-identity="filter-tags"]');

export const $FILTER_OPTION_1 = $('[data-identity="filter-option1"]');

export const $FILTER_OPTION_2 = $('[data-identity="filter-option2"]');

export const $FILTER_OPTION_3 = $('[data-identity="filter-option3"]');

export const $FILTER_MIN_PRICE = $('[data-identity="filter-min-price"]');

export const $FILTER_MAX_PRICE = $('[data-identity="filter-max-price"]');
