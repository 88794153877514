﻿/*
 * ==============================================================
 * Bobaweb Core
 * v1.0.0
 * by: Dom
 * ==============================================================
 * ==============================================================
 * ██████╗  ██████╗ ██████╗  █████╗ ██╗    ██╗███████╗██████╗ 
 * ██╔══██╗██╔═══██╗██╔══██╗██╔══██╗██║    ██║██╔════╝██╔══██╗
 * ██████╔╝██║   ██║██████╔╝███████║██║ █╗ ██║█████╗  ██████╔╝
 * ██╔══██╗██║   ██║██╔══██╗██╔══██║██║███╗██║██╔══╝  ██╔══██╗
 * ██████╔╝╚██████╔╝██████╔╝██║  ██║╚███╔███╔╝███████╗██████╔╝
 * ╚═════╝  ╚═════╝ ╚═════╝ ╚═╝  ╚═╝ ╚══╝╚══╝ ╚══════╝╚═════╝
 * ==============================================================
 * ==============================================================
 * 
 */

///*
// * Expose the Jquery into Global scope
// * 
// */
window.$ = window.jQuery = jQuery;

/*
 * Importing Bootstrap
 * 
 */
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';

/*
 * Importing Noty
 * 
 */
import Noty from 'noty/lib/noty.min.js';


/*
 * Importing Air Datepicker
 */
import 'air-datepicker/dist/js/datepicker.min.js';
import 'air-datepicker/dist/js/i18n/datepicker.en';

if ($('[data-action="datepicker"]').length || $('[data-action="datetimepicker"]').length) {

    $('[data-action="datepicker"]').datepicker({
        startDate: new Date(1990, 1, 1),
        dateFormat: 'dd M yyyy',
        language: 'en',
        autoClose: true
    });

    $('[data-action="datetimepicker"]').datepicker({
        startDate: new Date(),
        dateFormat: 'dd M yyyy',
        language: 'en',
        autoClose: true,
        timepicker: true,
        timeFormat: 'hh:ii'
    });

};

/*
 *  Create Notify instance and show it.
 *  Exposes it to global.
 */
window.Notify = function (message, type = 'alert', position = 'topCenter') {
    if (message) {
        new Noty({
            type,
            text: message,
            layout: position,
            theme: 'bootstrap-v4',
            timeout: 1500
        }).show();
    }
}

/*
 * Create Toastify instance and show it.
 * Exposes it to global.
 *
 * Toastify default config
 */
const TOAST_DEFAULT_CONFIG = {
    text: 'Toast!',
    duration: 5000,
    gravity: 'top',
    position: 'left'
}

window.Toaster = function (option = TOAST_DEFAULT_CONFIG) {
    Toastify(option)
        .showToast();
}

// membership
import './modules/membership/index';

// contact us
import './modules/contact-us/index';

// filtering
import './modules/filter/index';

// search
import './modules/search/index';

// ui
import './modules/ui/index';

//currency
import './modules/currency/index';

//custom form
import './modules/custom-form/index';

